<template>
  <!-- footer START -->
  <transition
    name="fade-slow"
    mode="out-in"
  >
    <div
      :class="[
        'footer',
        { 'show-footer' : visibleFooter }
      ]"
    >
      <!-- footer:tabs START -->
      <div class="nav-icons">
        <ul>
          <!-- footer:tabs:item START -->
          <li>
            <router-link
              :to="'/home'"
            >
              <img
                :src="iconQrcode"
                width=""
                height="24"
                alt="syreta - customer - footer - home"
              />
              <span v-html="$t('footer.door')"></span>
            </router-link>
          </li>
          <!-- footer:tabs:item END -->

          <!-- footer:tabs:item START -->
          <li>
            <router-link
              :to="'/scan'"
            >
              <img
                :src="iconScan"
                width=""
                height="24"
                alt="syreta - customer - footer - home"
              />
              <span v-html="$t('footer.scan')"></span>
            </router-link>
          </li>
          <!-- footer:tabs:item END -->

          <!-- footer:tabs:item START -->
          <li>
            <router-link
              :to="'/branch-finder'"
            >
              <img
                :src="iconHome"
                width=""
                height="24"
                alt="syreta - customer - footer - branch"
              />
              <span v-html="$t('footer.branch.second')"></span>
            </router-link>
          </li>
          <!-- footer:tabs:item END -->

          <!-- footer:tabs:item START -->
          <li>
            <router-link
              :to="'/account'"
            >
              <img
                :src="iconAccount"
                width=""
                height="24"
                alt="syreta - customer - footer - home"
              />
              <span v-html="$t('footer.account')"></span>
            </router-link>
          </li>
          <!-- footer:tabs:item END -->

        </ul>
      </div>
      <!-- footer:tabs END -->

    </div>
  </transition>
  <!-- footer END -->
</template>

<script>
import ICONS from '../../utils/icons';

const {
  iHome,
  iAccount,
  iQrcode,
  iScan,
} = ICONS;

export default {
  name: 'app-footer',
  data() {
    return {
      iconHome: localStorage && localStorage.getItem('theme') === 'light' ? iHome.defaultUrl : iHome.greenUrl,
      iconAccount: localStorage && localStorage.getItem('theme') === 'light' ? iAccount.defaultUrl : iAccount.greenUrl,
      iconQrcode: localStorage && localStorage.getItem('theme') === 'light' ? iQrcode.defaultUrl : iQrcode.greenUrl,
      iconScan: localStorage && localStorage.getItem('theme') === 'light' ? iScan.defaultUrl : iScan.greenUrl,
      visibleFooter: true,
    };
  },
  mounted() {
    // update app each time theme switched
    this.$root.$on('themeSwitch', () => {
      if (localStorage && localStorage.getItem('theme') === 'light') {
        this.iconHome = iHome.defaultUrl;
        this.iconAccount = iAccount.defaultUrl;
        this.iconQrcode = iQrcode.defaultUrl;
        this.iconScan = iScan.defaultUrl;
      } else {
        this.iconHome = iHome.greenUrl;
        this.iconAccount = iAccount.greenUrl;
        this.iconQrcode = iQrcode.greenUrl;
        this.iconScan = iScan.greenUrl;
      }
    });

    // show footer after component mounted
    this.$nextTick(() => {
      if (this.$route.name !== 'index'
        && this.$route.name !== 'register'
        && this.$route.name !== 'login'
        && this.$route.name !== 'sign-in'
        && this.$route.name !== 'reset-password'
        && this.$route.name !== 'logout'
        && this.$route.name !== null) {
        this.visibleFooter = true;
      } else {
        this.visibleFooter = false;
      }
    });
  },
  watch: {
    // show footer after component mounted
    $route(to) {
      this.$nextTick(() => {
        if (to.name !== 'index'
          && to.name !== 'register'
          && to.name !== 'login'
          && to.name !== 'sign-in'
          && to.name !== 'reset-password'
          && to.name !== 'logout'
          && to.name !== null) {
          setTimeout(() => {
            this.visibleFooter = true;
          }, 350);
        } else {
          this.visibleFooter = false;
        }
      });
    },
  },
};
</script>
